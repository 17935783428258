<template>
	<div class="common-header-container">
		<div style="display: flex;align-items: center;">
			<img :src="$store.state.userInfo.avatar" class="avatar" ></img>
			<div class="welcome-text">欢迎您！{{$store.state.userInfo.nick_name}}裁判长 登录园林国手平台！</div>
			<div class="tab-bar-box" v-if="barShow">
				<div class="tab-bar-item" :class="{'tab-bar-item-active' : $store.state.tabBarActive == 0}"
					@click="goPage(0)">竞赛设置</div>
				<div class="tab-bar-item" :class="{'tab-bar-item-active' : $store.state.tabBarActive == 1}"
					@click="goPage(1)">赛题录入</div>
				<div class="tab-bar-item" :class="{'tab-bar-item-active' : $store.state.tabBarActive == 2}"
					@click="goPage(2)">裁判及赛队</div>
				<div class="tab-bar-item" :class="{'tab-bar-item-active' : $store.state.tabBarActive == 3}"
					@click="goPage(3)">分组设置</div>
				<div class="tab-bar-item" :class="{'tab-bar-item-active' : $store.state.tabBarActive == 4}"
					@click="goPage(4)">竞赛详情</div>
				<div class="tab-bar-item" :class="{'tab-bar-item-active' : $store.state.tabBarActive == 5}"
					@click="goPage(5)">数据统计</div>
			</div>
		</div>
		<div class="btn-box">
			<el-button class="add-btn" icon="el-icon-plus" @click="addMatch" v-if="addMatchBtnShow">新增赛事</el-button>
			<el-button class="bc-btn" @click="$router.push('/')" v-if="backBtnShow">返回首页</el-button>
			<div class="img2" @click="logOut"></div>
		</div>
		
		<!-- 新增赛事 -->
		<el-dialog title="新增赛事" :visible.sync="dialogFormVisible" width="33%" class="dialog" top="8vh" @close="close" :append-to-body="true">
			<div slot="title" class="dialog-title">新增赛事</div>
			<el-form :model="form" :rules="rules" ref="ruleForm">
				<el-form-item label="赛事名称:" prop="name">
					<el-input v-model="form.name" class="name-input" placeholder="请输入赛事名称"></el-input>
				</el-form-item>
				<el-form-item label="主观输入类题目打分小数点保留:" prop="accuracy">
					<el-input
						oninput="value = value.replace(/[^\d]/g, '');if(value.length>2); value=value.slice(0,1);if(value>3)value=3"
						v-model="form.accuracy" placeholder="请输入内容" type="number" max="3" class="accuracy-input"
						min="0"></el-input>
				</el-form-item>
				<div class="line2"></div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="zbbc-btn" @click="dialogFormVisible = false">取消</el-button>
				<el-button class="bc-btn" @click="saveMatch">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		enableScoringStatus,
		getQueryCourseModule,
		raceSave
	} from '@/api/index.js'
	
	export default {
		name: "CommonHeader",
		data() {
			return {
				active: 0,
				dialogFormVisible:false,
				form: {
					name: '',
					accuracy: null,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入赛事名称',
						trigger: 'blur'
					}],
					accuracy: [{
						required: true,
						message: '请输入保留位数',
						trigger: 'blur'
					}]
				}
			};
		},
		props: {
			barShow: {
				type: Boolean,
				default: true
			},
			backBtnShow:{
				type: Boolean,
				default: true
			},
			addMatchBtnShow:{
				type: Boolean,
				default: false
			}
		},
		created() {
			this.enableScoringStatus()
		},
		methods: {
			async goPage(active) {
				// //进入这些页面前，现校验模块儿设置分值占比之和是否等于100%
				// if (active == 1 || active == 2 || active == 3 || active == 4 || active == 5) {
				// 	const res = await getQueryCourseModule({
				// 		raceId: this.$store.state.raceInfo.id
				// 	})
				// 	if (res.data.data.length > 0) {
				// 		let allPercentage = 0
				// 		res.data.data.forEach((item, index) => {
				// 			allPercentage += Number(item.percentage)
				// 		})
				// 		if (allPercentage != 100) {
				// 			this.$message({
				// 				type: 'warning',
				// 				message: '当前赛事所有模块分值占比之和不等于100%，请先设置正确后再进行操作'
				// 			});
				// 			return
				// 		}
				// 	}
				// }
				this.$store.commit('setTabBarActive', active)
				if (active == 0) this.$router.push({
					path: '/competitionSettings'
				})
				if (active == 1) this.$router.push({
					path: '/competitionEnter'
				})
				if (active == 2) this.$router.push({
					path: '/refereesAndTeams'
				})
				if (active == 3) this.$router.push({
					path: '/groupSettings'
				})
				if (active == 4) this.$router.push({
					path: '/competitionDetails'
				})
				if (active == 5) this.$router.push({
					path: '/dataStatistics'
				})

			},
			logOut() {
				this.$alert('确定退出登录吗？', '退出登录', {
					confirmButtonText: '确定',
					closeOnClickModal:true
				}).then(() => {
					this.$store.commit('logOut')
					this.$router.replace({
						path: '/login'
					})
					this.$message({
						type: 'success',
						message: '退出成功'
					});
				}).catch(() => {

				});

			},
			//获取当前赛事是否开启打分，如开启任意一个项目打分，将不可再对之前录入的数据进行任何操作（新增，编辑，删除）
			async enableScoringStatus(){
				const res = await enableScoringStatus({
					raceId: this.$store.state.raceInfo.id, //赛事id
				})
				// console.log(res,'enableScoringStatus')
				if(res.data.code == 200){
					this.$store.state.isEnableScoringStatus = res.data.data
				}
			},
			// 新增赛事按钮
			addMatch(){
				this.dialogFormVisible = true
			},
			//监听弹窗关闭方法
			close() {
				this.$refs.ruleForm.resetFields();
				this.form = {
					name: '',
				}
			},
			//弹窗保存
			saveMatch(){
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						const res = await raceSave({
							raceName:this.form.name,
							accuracy:this.form.accuracy,
							unit:'mm'
						})
						if (res.data.code == 200) {
							this.$message({
								message: '保存成功',
								type: 'success'
							})
							this.$emit('getMatchList')
						} else {
							this.$message.error(res.data.msg)
						}
						this.dialogFormVisible = false
					} else {
						console.log('校验失败')
						return false
					}
				})
			}
		}
	}
</script>
	<style>
		.el-button--primary {
			background-color: #007A69;
			border-color: #007A69;
		}

		.el-button--primary:focus,
		.el-button--primary:hover {
			background: #007A69;
			border-color: #007A69;
		}
	</style>
<style lang="scss" scoped>
	.common-header-container {
		position: sticky;
		top: 0;
		z-index: 9;
		width: 100%;
		height: 88px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 24px;
		box-sizing: border-box;
	}



	.avatar {
		width: 56px;
		height: 56px;
		border-radius: 50%;
	}

	.welcome-text {
		font-weight: 600;
		margin-left: 24px;
		white-space: nowrap;
		font-size: 20px;
		color: rgba(0, 0, 0, 0.9);
	}

	.tab-bar-box {
		width: 796px;
		height: 64px;
		background: #F3F5F6;
		border-radius: 8px;
		margin-left: 32px;
		display: flex;
		align-items: center;
		justify-content: space-around;

		.tab-bar-item {
			width: 120px;
			height: 56px;
			border-radius: 4px;
			font-size: 20px;
			color: rgba(0, 0, 0, 0.4);
			line-height: 56px;
			cursor: pointer;
			text-align: center;
			font-weight: 600;
		}

		.tab-bar-item-active {
			background-color: #fff;
			color: #007A69;
		}
	}

	.btn-box {
		display: flex;
		align-items: center;
		.add-btn {
			width: 164px;
			height: 56px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #007A69;
			font-size: 20px;
			color: #007A69;
			font-weight: 600;
			margin-right: 16px;
		}
		.bc-btn {
			width: 150px;
			height: 56px;
			background: #007A69;
			border-radius: 4px;
			font-size: 20px;
			font-weight: 600;
			color: #FFFFFF;
			margin-right: 17px;
		}

		.img1 {
			width: 56px;
			height: 56px;
			background: url('../assets/CommonHeader/info.png') no-repeat center;
			background-size: 100%;
			margin: 17px;
			position: relative;
			cursor: pointer;
		}

		.img2 {
			width: 56px;
			height: 56px;
			background: url('../assets/CommonHeader/logout.png') no-repeat center;
			background-size: 100%;
			cursor: pointer;
		}


		.img1:active {
			width: 56px;
			height: 56px;
			background: url('../assets/CommonHeader/info-active.png') no-repeat center;
			background-size: 100%;
			margin-right: 17px;
		}

		.img2:active {
			width: 56px;
			height: 56px;
			background: url('../assets/CommonHeader/logout-active.png') no-repeat center;
			background-size: 100%;
		}


	}
	
	.dialog {
		::v-deep .el-form-item__label {
			font-size: 20px;
			color: rgba(0, 0, 0, 0.9);
			font-weight: 600;
		}
	
		::v-deep .el-form-item__label {
			width: auto;
			text-align: right;
		}
	
		.dialog-title {
			height: 60px;
			// background-color: red;
			font-size: 24px;
			font-weight: 600;
			color: rgba(0, 0, 0, 0.9);
			border-bottom: 1px solid #e7e7e7;
		}
	
		::v-deep .el-form-item__content {
			display: flex;
			justify-content: start;
		}
	
		::v-deep .name-input .el-input__inner,
		.name-input {
			border: none;
			background: #F8F7F0;
			border-radius: 28px;
			width: 406px;
			height: 48px;
			border-radius: 28px;
		}
		
	
		::v-deep .accuracy-input .el-input__inner,
		.accuracy-input {
			border: none;
			background: #F8F7F0;
			width: 210px;
			height: 48px;
			background: #F8F7F0;
			border-radius: 28px;
		}
	
		::v-deep .accuracy-input .el-input__inner,
		::v-deep .name-input .el-input__inner {
			font-size: 18px;
			color: rgba(0, 0, 0, 0.9);
			font-weight: 600;
	
			&::placeholder {
				font-size: 18px;
				color: rgba(0, 0, 0, 0.4);
				font-weight: 600;
			}
		}
	
	
		.line {
			width: 586px;
			height: 1px;
			background-color: #E7E7E7;
		}
	
		.line2 {
			width: 586px;
			height: 1px;
			background-color: #E7E7E7;
			margin: 24px 0;
		}
	
		::v-deep .el-dialog__headerbtn {
			font-size: 30px !important;
			font-weight: bold;
		}
	
		.dialog-footer {
			display: flex;
			justify-content: flex-end;
	
			.zbbc-btn {
				width: 156px;
				height: 56px;
				background: #FFFFFF;
				border-radius: 4px;
				border: 1px solid rgba(0, 0, 0, 0.16);
				font-size: 20px;
				font-weight: 600;
				color: rgba(0, 0, 0, 0.4);
			}
	
			.bc-btn {
				width: 156px;
				height: 56px;
				background: #007A69;
				border-radius: 4px;
				font-size: 20px;
				font-weight: 600;
				color: #FFFFFF;
			}
		}
	
	}
</style>